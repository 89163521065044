import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
// ng-zorro
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { NzInputModule } from 'ng-zorro-antd/input';
// components
import { CoreChatComponent } from './core-chat.component';
import { EmailHelperComponent } from './components';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    NzIconModule,
    NzButtonModule,
    NzSelectModule,
    NzModalModule,
    NzInputModule,
  ],
  exports: [CoreChatComponent],
  declarations: [CoreChatComponent, EmailHelperComponent],
})
export class CoreChatModule {}
