import { ChangeDetectorRef, Component } from '@angular/core';
import { firstValueFrom, of } from 'rxjs';
import { delay, take } from 'rxjs/operators';
// services
import { LoadingService } from 'src/app/core/services/loading/loading.service';

@Component({
  selector: 'app-email-helper',
  templateUrl: './email-helper.component.html',
})
export class EmailHelperComponent {
  inputValue: string;
  textValue: string;

  constructor(private cd: ChangeDetectorRef, public loadingService: LoadingService) {
    this.inputValue = '비엘 번호 abcdefg123482 의 선적건이 지연되고 있는지, 선적지 항구에서 배가 출항했는지';
  }

  //#region events
  async onWrite() {
    if (!this.inputValue) {
      alert('Please enter your content');
      return;
    }
    this.loadingService.show();
    const mainContent = await firstValueFrom(of(this.generateEmailContent()).pipe(take(1), delay(700)));
    this.loadingService.hide();
    this.textValue = mainContent;
    this.cd.markForCheck();
  }

  onCopy() {
    if (this.textValue?.length > 0) {
      window.navigator.clipboard.writeText(this.textValue).then(() => {
        alert('Copied to clipboard');
      });
    } else {
      alert('Please write your content');
    }
  }
  //#endregion

  private generateEmailContent() {
    return `
    Subject: Inquiry on Shipment Status for BL Number ABCDEFG123482
    Dear Sir/Madam,\n
    I hope this email finds you well. I am writing to kindly request your assistance in obtaining an update on the current status of the shipment for the BL number ABCDEFG123482.
    I have not received any recent information on the whereabouts of the shipment, and I would be grateful if you could provide me with an update on the following:\n
    Has the shipment been delayed? If so, could you please advise me on the cause of the delay and the expected time of arrival?
    Has the vessel departed from the port of origin? If so, could you please inform me of the date of departure and the expected time of arrival at the destination port?\n
    I understand that you have many inquiries to handle, and I appreciate your time and effort in providing me with this information. If there are any additional details that you require from me, please do not hesitate to let me know, and I will be happy to provide them.\n
    Thank you for your attention to this matter, and I look forward to hearing back from you.\n\n
    Best regards,
    [Your Name]
    `;
  }
}
