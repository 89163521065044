<div id="header">
  <div [@openOrClose]="headerOpenYn ? 'opened' : 'closed'" class="header-wrap">
    <h1>
      <a [routerLink]="['/']"><img src="../assets/images/logo.gif" alt="WISECONVEY logo" /></a>
    </h1>
    <nav #navArea>
      <ul class="gnb">
        <li>
          <a [routerLink]="['/main']" [class.active1]="activatedMenu === 'dashboard'">Workspace</a>
          <ul class="two-depth">
            <li><a [routerLink]="['/main']" routerLinkActive="active2">To do list</a></li>
            <li><a [routerLink]="['/shipment']" routerLinkActive="active2">Inbound Shipping Status</a></li>
            <li>
              <a [routerLink]="['/freetime']" routerLinkActive="active2">Freetime Info Management</a>
            </li>
          </ul>
        </li>
        <li>
          <a [routerLink]="['/bl-master']" [class.active1]="activatedMenu === 'document'">Document</a>
          <ul class="two-depth">
            <li><a [routerLink]="['/bl-master']" routerLinkActive="active2">Upload B/L</a></li>
            <li><a [routerLink]="['/sr-master']" routerLinkActive="active2">Create S/R</a></li>
          </ul>
        </li>
        <li>
          <a [routerLink]="['/admin-dashboard']" routerLinkActive="active1">Report</a>
          <ul class="two-depth">
            <li>
              <a [routerLink]="['/admin-dashboard']" routerLinkActive="active2">Performance Dashboard</a>
            </li>
          </ul>
        </li>
      </ul>
    </nav>
    <div class="util-menu">
      <ul>
        <li>
          <span>{{ userName }}</span>
        </li>
        <li><button type="button" (click)="onClickLogout()">Logout</button></li>
        <li>
          <button type="button" (click)="onToggleCoreChat()">
            <img src="../assets/images/corechat.png" alt="corechat character" /><span>C</span>ore<span>C</span>hat
          </button>
        </li>
      </ul>
    </div>
  </div>
</div>
