import {
  AfterViewChecked,
  Component,
  ElementRef,
  OnInit,
  ViewChild,
  ChangeDetectionStrategy,
  ViewEncapsulation,
  ChangeDetectorRef,
} from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { trigger, style, transition, animate, state } from '@angular/animations';
import { Router } from '@angular/router';
import { filter, skip } from 'rxjs/operators';
// services
import { AppService } from 'src/app/app.service';
import { CoreChatService } from './core-chat.service';
// models
import { IMessage } from './core-chat-types';

@Component({
  selector: 'app-core-chat',
  templateUrl: './core-chat.component.html',
  animations: [
    trigger('animation1', [
      state(
        'opened',
        style({
          width: '22vw',
        }),
      ),
      state(
        'closed',
        style({
          width: '0',
        }),
      ),
      transition('opened <=> closed', [animate('0.5s')]),
    ]),
    trigger('animation2', [
      state(
        'hide',
        style({
          height: '0',
        }),
      ),
      state(
        'show',
        style({
          height: '170px',
        }),
      ),
      transition('hide <=> show', [animate('0.3s')]),
    ]),
    trigger('animation3', [
      state(
        'rotate45',
        style({
          transform: 'rotate(45deg)',
        }),
      ),
      state(
        'rotate0',
        style({
          transform: 'rotate(0deg)',
        }),
      ),
      transition('rotate45 <=> rotate0', [animate('0.3s')]),
    ]),
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class CoreChatComponent implements OnInit, AfterViewChecked {
  messageList: IMessage[] = [];
  tempDemoMessgeList: string[] = [
    '고려해운의 현재 부킹 가능한 스케쥴은 4워 20일, 4월 21일, 4월 23일가 있습니다.',
    '4월 20일 부산 상해 구간 으로 20피트 일반 컨테이너 1대 부킹하였습니다. 부킹 번호는 WCB000000038 입니다. S/R을 작성하시겠습니까?',
    'S/R작성을 위한 파일을 업로드 해주세요.',
    'S/R 작성이 완료 되었습니다. 확인을 위해 팬텀해운 홈페이지로 이동하시겠습니까?<br><a id="hyper-link">https://www.wiseconvey.com</a>',
    '오늘 해야할 일을 To-Do List 로 정리해드리겠습니다.<br>1. 이메일 확인<br>2. 서류작성 : 진행 예정 건 중 서류 작성이 안 된 부킹이 3건 있습니다.<br>3. 선적스케쥴 확인 : ETD가 지난 건 중 B/L 정보가 없는 부킹 2건 있습니다.<br>4. 출항 완료된 건 중 파트너에게 공유되지 않은 B/L 이 1건 있습니다.<br>5. 프리타임 종료 3일 전인 선적건이 6건 있습니다.<br>6. 오늘 오후 4시에 업무 회의가 예정되어 있습니다.',
    '프리타임 종료 3일 전인 선적 건은 HSGSL00023919, JSSOEI92819902A, GGMS00A99281 입니다. 발송 할까요?',
    '발송 완료하였습니다.',
    'B/L 정보가 없는 2건 확인을 위해 선적현황표로 이동하시겠습니까?<br><a id="router-link">[선적현황표]</a>',
    '2023년 4월 현재까지의 실적은 다음과 같습니다:<br>TOTAL: 1,033 TEU<br>수출: 562 TEU<br>수입: 471 TEU<br>매출: USD 113,689<br>영업이익: USD 10,987',
    '먼저 2월과 3월의 실적을 비교 분석하겠습니다.<br><br>2023년 2월 실적:<br>TOTAL: 3328 TEU<br>수출: 1882 TEU<br>수입: 1446 TEU<br>매출: USD 354,432<br>영업이익: USD 47,233<br><br>2. 2023년 3월 실적:<br>TOTAL: 4002 TEU (증가율: 20.2%)<br>수출: 2300 TEU (증가율: 22.2%)<br>수입: 1702 TEU (증가율: 17.7%)<br>매출: USD 418,221 (증가율: 18.0%)<br>영업이익: USD 49,724 (증가율: 5.3%)<br><br>3. 분석 결과:<br>TEU 전체, 수출, 수입 모두 2월 대비 3월에 증가했습니다.<br>매출도 2월 대비 3월에 증가했지만, 영업이익 증가율은 상대적으로 낮습니다.<br><br>4. 2023년 4월 예상 실적<br>TOTAL: 4802 TEU (증가율: 20%)<br>수출: 2760 TEU (증가율: 20%)<br>수입: 2042 TEU (증가율: 20%)<br>매출: USD 501,865 (증가율: 20%)<br>영업이익: USD 59,669 (증가율: 20%)',
    '국가별 선적 실적 비율을 아래와 같이 작성했습니다.<br><br>미국<br>수출: (450/3550) * 100 = 12.68%<br>수입: (380/3230) * 100 = 11.76%<br>중국<br>수출: (600/3550) * 100 = 16.9%<br>수입: (680/3230) * 100 = 21.05%<br>일본<br>수출: (320/3550) * 100 = 9.01%<br>수입: (290/3230) * 100 = 8.97%<br>대한민국<br>수출: (550/3550) * 100 = 15.49%<br>수입: (480/3230) * 100 = 14.86%<br>인도<br>수출: (420/3550) * 100 = 11.83%<br>수입: (360/3230) * 100 = 11.15%<br>베트남<br>수출: (280/3550) * 100 = 7.89%<br>수입: (320/3230) * 100 = 9.91%<br>태국<br>수출: (250/3550) * 100 = 7.04%<br>수입: (270/3230) * 100 = 8.36%<br>싱가포르<br>수출: (300/3550) * 100 = 8.45%<br>수입: (310/3230) * 100 = 9.6%<br>독일<br>수출: (200/3550) * 100 = 5.63%<br>수입: (220/3230) * 100 = 6.81%<br>영국<br>수출: (180/3550) * 100 = 5.07%<br>수입: (210/3230) * 100 = 6.5%<br><br>이 데이터를 통해 중국이 수출 및 수입에서 가장 큰 비중을 차지하고 있음을 알 수 있습니다. 미국, 대한민국, 인도 등 다른 국가들도 상대적으로 높은 비중을 가지고 있으며, 독일과 영국은 비교적 낮은 비중을 가지고 있습니다.',
    'Currently, the available schedules are May 5, May 6, and May 7.',
    'We have booked 1 20ft general container on May 5 between Busan and Shanghai. The booking number is WCB000000038. Do you want to create an S/R?',
    'Please upload a file for S/R creation.',
    'The S/R has been completed, would you like to go to the WiseConvey homepage for confirmation?<br><a id="hyper-link">https://www.wiseconvey.com</a>',
    "Here's your To-Do List for today<br/>1. check email<br/>2. Documentation: There are 3 incomplete bookings among the scheduled cases.<br/>3. Check shipment schedule : There are 2 bookings that are past ETD and do not have B/L information.<br/>4. 1 bookings that have shipped have a B/L that has not been shared with the partner.<br/>5. There are 6 shipments that are 3 days before the end of free time.<br/>6. You have a business meeting scheduled for 4:00 PM today.",
    'The shipments that are 3 days before the end of free time are HSGSL00023919, JSSOEI92819902A, GGMS00A99281. Should I send a notification?',
    'Sent it.',
    'Would you like to go to the shipment status to check the 2 shipments without B/L information?<br><a id="router-link">[Shipment Status]</a>',
    'Our performance as of April 2023 is as follows:<br/>TOTAL: 1,033 TEUs<br/>Export: 562 TEU<br/>Import: 471 TEU<br/>Revenue: USD 113,689<br/>Profit: USD 10,987',
    "First, let's analyze the performance of February and March.<br/><br/>February 2023 performance:<br/>TOTAL: 3328 TEU<br/>Export: 1882 TEU<br/>Import: 1446 TEU<br/>Revenue: USD 354,432<br/>Profit: USD 47,233<br/><br/>March 2023 results:<br/>TOTAL: 4002 TEU (up 20.2%)<br/>Export: 2300 TEU (+22.2%)<br/>Import: 1702 TEU (up 17.7%)<br/>Revenue: USD 418,221 (up 18.0%)<br/>Profit: USD 49,724 (up 5.3%)<br/><br/>Analysis:<br/>Total TEUs, export, and import all increased in March compared to February.<br/>Revenue also increased in March compared to February, but the increase in operating profit is relatively low.<br/><br/>Estimated performance for April 2023<br/>TOTAL: 4802 TEU (growth rate: 20%)<br/>Export: 2760 TEU (growth rate: 20%)<br/>Import: 2042 TEU (+20%)<br/>Revenue: USD 501,865 (up 20%)<br/>Profit: USD 59,669 (up 20%)",
    'We have compiled the percentage of shipments by country as follows.<br/><br/>United States<br/>Export: (450/3550) * 100 = 12.68%<br/>Import: (380/3230) * 100 = 11.76%<br/><br/>China<br/>Export: (600/3550) * 100 = 16.9%<br/>Import: (680/3230) * 100 = 21.05%<br/><br/>Japan<br/>Export: (320/3550) * 100 = 9.01%<br/>Import: (290/3230) * 100 = 8.97%<br/><br/>South Korea<br/>Export: (550/3550) * 100 = 15.49%<br/>Import: (480/3230) * 100 = 14.86%<br/><br/>India<br/>Export: (420/3550) * 100 = 11.83% <br/>Import: (360/3230) * 100 = 11.15%<br/><br/>Vietnam<br/>Export: (280/3550) * 100 = 7.89%<br/>Import: (320/3230) * 100 = 9.91%<br/><br/>Thailand<br/>Export: (250/3550) * 100 = 7.04%<br/>Import: (270/3230) * 100 = 8.36%<br/><br/>Singapore<br/>Export: (300/3550) * 100 = 8.45%<br/>Import: (310/3230) * 100 = 9.6%<br/><br/>Germany<br/>Export: (200/3550) * 100 = 5.63%<br/>Import: (220/3230) * 100 = 6.81%<br/><br/>United Kingdom<br/>Export: (180/3550) * 100 = 5.07%<br/>Import: (210/3230) * 100 = 6.5%<br/><br/>From this data, we can see that China accounts for the largest share of exports and imports. Other countries such as the United States, South Korea, and India also have relatively high shares, while Germany and the United Kingdom have relatively low shares.',
  ];
  navToShipmentYn: boolean = false;
  navToSrYn: boolean = false;
  navToSrIndex: number;
  openChatYn: boolean = true;
  emailModalOpenYn: boolean = false;
  showMenuYn: boolean = false;
  scrollToBottomYn: boolean = false;
  categorySelected: string = 'MBL';

  constructor(
    private sanitizer: DomSanitizer,
    private elementRef: ElementRef,
    private router: Router,
    private coreChatService: CoreChatService,
    private cd: ChangeDetectorRef,
    private appService: AppService,
  ) {}

  ngOnInit(): void {
    this.initSubscription();
  }

  onCloseChat() {
    this.coreChatService.closeChat();
  }

  onOpenEmailModal() {
    this.emailModalOpenYn = true;
  }
  onCloseEmailModal() {
    this.emailModalOpenYn = false;
  }

  onNzOpenChange(status: boolean) {
    if (status) {
      this.appService.disableScroll();
    } else {
      this.appService.enableScroll();
    }
  }

  @ViewChild('userMessage') userMessageElement: ElementRef;
  @ViewChild('categorySelect') categorySelectElement: ElementRef;
  @ViewChild('categoryMessage') categoryMessageElement: ElementRef;
  @ViewChild('chatLayer', { static: false }) chatLayerElement: ElementRef;

  // user message send event
  onInputUserMessage(message: string) {
    if (message === '') {
      return;
    }
    // user message push
    this.messageList.push({ role: 'user', message: message });
    // 채팅창에 챗 추가시 스크롤 최신화
    this.scrollToBottomYn = true;

    // corechat 응답 push
    if (message.includes('선적') && message.includes('스케쥴')) {
      this.onSetCoreChatMessage(this.tempDemoMessgeList[0], 0);
    } else if (message.includes('4월 20익') || message.includes('부킹')) {
      this.onSetCoreChatMessage(this.tempDemoMessgeList[1], 1);
    } else if (message.includes('작성')) {
      this.onSetCoreChatMessage(this.tempDemoMessgeList[2], 2);
    } else if (message.includes('해야할 일')) {
      this.onSetCoreChatMessage(this.tempDemoMessgeList[4], 4);
    } else if (message.includes('프리타임 3일 남은')) {
      this.onSetCoreChatMessage(this.tempDemoMessgeList[5], 5);
    } else if (message.includes('응') && message.includes('발송해')) {
      this.onSetCoreChatMessage(this.tempDemoMessgeList[6], 6);
    } else if (message.includes('비엘 없는')) {
      this.onSetCoreChatMessage(this.tempDemoMessgeList[7], 7);
    } else if (message.includes('4월 실적 현황')) {
      this.onSetCoreChatMessage(this.tempDemoMessgeList[8], 8);
    } else if (message.includes('최근 월간 실적') && message.includes('분석')) {
      this.onSetCoreChatMessage(this.tempDemoMessgeList[9], 9);
    } else if (message.includes('국가별 선적 현황')) {
      this.onSetCoreChatMessage(this.tempDemoMessgeList[10], 10);
    } else if (message.includes('schedule') && message.includes('delivered')) {
      this.onSetCoreChatMessage(this.tempDemoMessgeList[11], 11);
    } else if (message.includes('book') && message.includes('May 5')) {
      this.onSetCoreChatMessage(this.tempDemoMessgeList[12], 12);
    } else if (message.includes('Create') && message.includes('it')) {
      this.onSetCoreChatMessage(this.tempDemoMessgeList[13], 13);
    } else if (message.includes('to do list')) {
      this.onSetCoreChatMessage(this.tempDemoMessgeList[15], 15);
    } else if (message.includes('3 days left of free time')) {
      this.onSetCoreChatMessage(this.tempDemoMessgeList[16], 16);
    } else if (message.includes('Yes') && message.includes('send it')) {
      this.onSetCoreChatMessage(this.tempDemoMessgeList[17], 17);
    } else if (message.includes('no B/L shipment')) {
      this.onSetCoreChatMessage(this.tempDemoMessgeList[18], 18);
    } else if (message.includes('April performance status')) {
      this.onSetCoreChatMessage(this.tempDemoMessgeList[19], 19);
    } else if (message.includes('analyze') && message.includes('recent monthly performance')) {
      this.onSetCoreChatMessage(this.tempDemoMessgeList[20], 20);
    } else if (message.includes('Shipment Status by Country')) {
      this.onSetCoreChatMessage(this.tempDemoMessgeList[21], 21);
    }

    // this.onInputUserCategoryMessage(this.categoryMessageElement.nativeElement.value);
    // input의 #userMessage의 value 초기화ue = '';
    this.userMessageElement.nativeElement.value = '';
  }

  onCompletedSR() {
    if (this.navToSrIndex === 2) {
      // 한글
      this.onSetCoreChatMessage(this.tempDemoMessgeList[3], 3);
    } else {
      // 영어
      this.onSetCoreChatMessage(this.tempDemoMessgeList[14], 14);
    }
  }

  // input user categroy message send event
  onInputUserCategoryMessage(message: string) {
    var index = this.categorySelectElement.nativeElement.selectedIndex;
    console.log(this.categorySelectElement.nativeElement.options[index].value);
  }

  onVisible() {
    // console.log(this.messageList);
    // var visibility = this.chatMenuElement.nativeElement.style.visibility;
    // if (visibility === 'hidden') {
    //   this.chatMenuElement.nativeElement.style.visibility = 'visible';
    // } else {
    //   this.chatMenuElement.nativeElement.style.visibility = 'hidden';
    // }
    this.showMenuYn = !this.showMenuYn;
  }

  // coreChat bot message response event
  onSetCoreChatMessage(message: string, index: number) {
    setTimeout(() => {
      this.messageList.push({ role: 'coreChat', message: this.sanitizer.bypassSecurityTrustHtml(message) });
      this.cd.markForCheck();
      // 채팅창에 챗 추가시 스크롤 최신화
      this.scrollToBottomYn = true;
    }, 1000);

    setTimeout(() => {
      if (index === 1 || index === 12) {
        this.navToShipmentYn = true;
        this.router.navigateByUrl('/shipment?initialyn=true');
      } else if (index === 2 || index === 13) {
        this.navToSrYn = true;
        this.navToSrIndex = index;
        this.router.navigateByUrl('/sr-master?fromcc=true');
      }
      this.cd.markForCheck();
    }, 1000);
  }

  routingRouterLink() {
    // routting shipment
    this.router.navigate(['/shipment']);
  }

  hyperLink() {
    window.open('https://www.wiseconvey.com', '_blank');
  }

  ngAfterViewChecked() {
    if (this.elementRef.nativeElement.querySelector('#router-link')) {
      this.elementRef.nativeElement
        .querySelector('#router-link')
        .addEventListener('click', this.routingRouterLink.bind(this));
    }

    if (this.elementRef.nativeElement.querySelector('#hyper-link')) {
      this.elementRef.nativeElement
        .querySelector('#hyper-link')
        .addEventListener('click', this.hyperLink.bind(this));
    }

    if (this.scrollToBottomYn) {
      this.chatLayerElement.nativeElement.scrollTop = this.chatLayerElement.nativeElement.scrollHeight;
      this.scrollToBottomYn = false;
    }
  }

  private initSubscription() {
    this.coreChatService.srCompleteToggle$.pipe(filter((val) => val !== null)).subscribe(() => {
      this.onCompletedSR();
    });

    this.coreChatService.openChatYn$.pipe(skip(1)).subscribe((openYn) => {
      this.openChatYn = openYn;
      this.cd.markForCheck();
    });
  }
}
