import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
// guard
import { authGuard } from 'src/app/core/guard/auth.guard';

const routes: Routes = [
  { path: '', redirectTo: '/main', pathMatch: 'full' },
  {
    path: 'main',
    loadChildren: () => import('./routes/dashboard/dashboard.module').then((m) => m.DashboardModule),
    canActivate: [authGuard],
  },
  {
    path: 'admin-dashboard',
    loadChildren: () =>
      import('./routes/admin-dashboard/admin-dashboard.module').then((m) => m.AdminDashboardModule),
    canActivate: [authGuard],
  },
  {
    path: 'shipment',
    loadChildren: () => import('./routes/shipment/shipment.module').then((m) => m.ShipmentModule),
    canActivate: [authGuard],
  },
  {
    path: 'freetime',
    loadChildren: () => import('./routes/freetime/freetime.module').then((m) => m.FreetimeModule),
    canActivate: [authGuard],
  },
  {
    path: 'sr-master',
    loadChildren: () => import('./routes/sr-master/sr-master.module').then((m) => m.SrMasterModule),
    canActivate: [authGuard],
  },
  {
    path: 'bl-master',
    loadChildren: () => import('./routes/bl-master/bl-master.module').then((m) => m.BlMasterModule),
    canActivate: [authGuard],
  },
  {
    path: 'login',
    loadChildren: () => import('src/app/auth/auth.module').then((m) => m.AuthModule),
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
