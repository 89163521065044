import { inject } from '@angular/core';
import { CanActivateFn, CanMatchFn, Router } from '@angular/router';
// services
import { AuthService } from 'src/app/auth/auth.service';

export const authGuard: CanActivateFn | CanMatchFn = () => {
  const authService = inject(AuthService);
  const router = inject(Router);

  if (authService.getLoginYn()) {
    return true;
  }
  // redirect to login page
  return router.parseUrl('/login');
};
