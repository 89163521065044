import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CommonModule, LocationStrategy, PathLocationStrategy, registerLocaleData } from '@angular/common';
import en from '@angular/common/locales/en';
import { HttpClientModule } from '@angular/common/http';
import { COMPOSITION_BUFFER_MODE } from '@angular/forms';
registerLocaleData(en);

// zorro
import { NZ_I18N, en_US } from 'ng-zorro-antd/i18n';
// custom modules
import { LayoutModule } from 'src/app/shared/modules/layout/layout.module';
import { CoreChatModule } from 'src/app/shared/modules/core-chat/core-chat.module';
import { ScrollToModule } from 'src/app/shared/modules/scroll-to/scroll-to.module';
// models
import { HTTP_HEADER_LOADING } from './app.config';
// components
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    CommonModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AppRoutingModule,
    LayoutModule,
    CoreChatModule,
    ScrollToModule.forRoot(),
  ],
  providers: [
    {
      // url에 # 사용하지 않은 방식으로 설정
      // HashLocationStrategy: # 사용
      // PathLocationStrategy: # 사용안함
      provide: LocationStrategy,
      useClass: PathLocationStrategy,
    },
    {
      // Angular 양방향 바인딩에서 한글이 짤리는 문제
      provide: COMPOSITION_BUFFER_MODE,
      useValue: false,
    },
    // API 호출시 글로벌 로딩여부를 판단하는 키값을 토큰에 담는다
    { provide: HTTP_HEADER_LOADING, useValue: 'global-loading' },
    // ng-zorro-antd 다국어 설정
    { provide: NZ_I18N, useValue: en_US },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
