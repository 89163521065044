import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
// containers & components
import { HeaderComponent, FooterComponent } from './components';

@NgModule({
  imports: [CommonModule, RouterModule.forChild([])],
  exports: [HeaderComponent, FooterComponent],
  declarations: [HeaderComponent, FooterComponent],
})
export class LayoutModule {}
