import { Component, ChangeDetectorRef, AfterViewInit, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { trigger, style, state, transition, animate } from '@angular/animations';
import { fromEvent, Subject } from 'rxjs';
import { takeUntil, delay, filter, tap } from 'rxjs/operators';
import { get } from 'lodash-es';
// services
import { AppService } from 'src/app/app.service';
import { AuthService } from 'src/app/auth/auth.service';
import { CoreChatService } from 'src/app/shared/modules/core-chat/core-chat.service';

@Component({
  selector: 'app-layout-header',
  templateUrl: './header.component.html',
  animations: [
    trigger('openOrClose', [
      state('opened', style({ height: '230px' })),
      state('closed', style({ height: '60px' })),
      transition('opened => closed', [animate('.1s')]),
      transition('closed => opened', [animate('.3s')]),
    ]),
  ],
})
export class HeaderComponent implements AfterViewInit, OnDestroy {
  @ViewChild('navArea') navElRef: ElementRef;

  private $unsubscribe = new Subject<void>();
  // 1뎁스 메뉴랑 2뎁스 메뉴를 매칭하는 부분. 메뉴 추가됨에 따라 해당 컬렉션이 늘어난다.
  menuCollection = {
    '/bl-master': 'document',
    '/sr-master': 'document',
    '/history': 'document',
    '/freetime': 'dashboard',
    '/shipment': 'dashboard',
    '/main': 'dashboard',
    '/admin-dashboard': 'basis',
  };

  // states
  userName: string;
  activatedMenu: string;
  headerOpenYn: boolean;
  mouseOnNav: boolean;

  //#region init functions
  constructor(
    private authService: AuthService,
    private appService: AppService,
    private cd: ChangeDetectorRef,
    private coreChatService: CoreChatService,
    private router: Router,
  ) {
    this.userName = this.authService.getUserName();

    // router subscribe
    this.appService.currentUrl$.subscribe((url) => {
      this.activatedMenu = get(this.menuCollection, url, this.activatedMenu);
      this.cd.markForCheck();
    });
  }
  ngAfterViewInit(): void {
    // 2뎁스 메뉴 열기
    fromEvent(this.navElRef.nativeElement, 'mouseover')
      .pipe(
        takeUntil(this.$unsubscribe),
        tap(() => (this.mouseOnNav = true)),
        filter(() => !this.headerOpenYn),
      )
      .subscribe(() => (this.headerOpenYn = true));
    // 2뎁스 메뉴 닫기
    fromEvent(this.navElRef.nativeElement, 'mouseout')
      .pipe(
        takeUntil(this.$unsubscribe),
        tap(() => (this.mouseOnNav = false)),
        delay(1000),
        filter(() => this.headerOpenYn && !this.mouseOnNav),
      )
      .subscribe(() => (this.headerOpenYn = false));
  }
  ngOnDestroy(): void {
    this.$unsubscribe.next();
    this.$unsubscribe.complete();
  }
  //#endregion

  //#region event
  onClickLogout() {
    this.authService.logout();
    this.router.navigate(['/login']);
  }

  onToggleCoreChat() {
    this.coreChatService.toggleOpenChatYn();
  }
  //#endregion
}
