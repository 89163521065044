<div class="email-helper">
  <p>What to include in the mail</p>
  <nz-input-group [nzSuffix]="inputClearTpl">
    <input type="text" nz-input [(ngModel)]="inputValue" placeholder="what to include in the mail" />
  </nz-input-group>
  <ng-template #inputClearTpl>
    <span
      nz-icon
      class="ant-input-clear-icon"
      nzTheme="fill"
      nzType="close-circle"
      *ngIf="inputValue"
      (click)="inputValue = null"
    ></span>
  </ng-template>
  <textarea nz-input [(ngModel)]="textValue"></textarea>
  <div class="email-helper-btn">
    <button type="button" (click)="onWrite()">Write</button>
    <button type="button" (click)="onCopy()">
      <span>Copy</span><img src="../assets/images/copy.png" alt="document icon" />
    </button>
  </div>
</div>
