import { Injectable } from '@angular/core';
import { FormBuilder, FormControl, Validators, FormGroup } from '@angular/forms';
import { Observable, of } from 'rxjs';
import { delay, switchMap, finalize } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { get } from 'lodash-es';
// services
import { LoadingService } from 'src/app/core/services/loading/loading.service';
// models
import { IUserInfo } from './auth.types';

@Injectable({ providedIn: 'root' })
export class AuthService {
  apiPath = {
    signin: `${environment.apiUrl}/api/users/signin`,
    refreshToken: `${environment.apiUrl}/api/users/tokens`,
  };

  // states
  #userInfo: IUserInfo;

  constructor(private fb: FormBuilder, private loadingService: LoadingService) {
    // init user info from session storage
    this.#userInfo = this.#getSessionStorage('userInfo');
  }

  //#region data service
  /** login form */
  generateLoginForm(): FormGroup {
    return this.fb.group({
      email: new FormControl('', [Validators.required, Validators.email]),
      password: new FormControl('', [Validators.required]),
    });
  }

  /** get user info */
  getUserInfo(): IUserInfo {
    return this.#userInfo;
  }
  getLoginYn(): boolean {
    return !!this.#userInfo;
  }
  getUserName(): string {
    return get(this.#userInfo, 'name', '');
  }
  //#endregion

  //#region api service
  /** login http request */
  loginHttp(_params: any): Observable<IUserInfo> {
    const mockupUser = this.#generateMockupUser();
    this.loadingService.show();
    return of(mockupUser).pipe(
      delay(1500),
      switchMap((info) => {
        this.#userInfo = info;
        this.#setSessionStorage('userInfo', JSON.stringify(info));
        return of(info);
      }),
      finalize(() => {
        this.loadingService.hide();
      }),
    );
  }
  /** logout */
  logout(): void {
    this.#userInfo = null;
    this.#setSessionStorage('userInfo', null);
  }

  /** make mockup user */
  #generateMockupUser(): IUserInfo {
    return {
      userSeq: 1,
      name: '황재원',
      email: 'jwhwang@weraser.com',
      accessToken:
        'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MSwiaWF0IjoxNjc4OTQ2NjIyLCJleHAiOjE2NzkwMzMwMjJ9.PXnAhuAa3hJqQm7b1Ou_y7sDBAEz5rJwEFhcBiqOTTM',
      refreshToken:
        'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MSwiaWF0IjoxNjc4OTQ2NjIyLCJleHAiOjE2NzkwMzMwMjJ9.PXnAhuAa3hJqQm7b1Ou_y7sDBAEz5rJwEFhcBiqOTTM',
      branchSeq: 1,
    };
  }

  /** session storage -> set item */
  #setSessionStorage(key: string, value: string): void {
    window.sessionStorage.setItem(key, value);
  }
  /** session storage -> get item */
  #getSessionStorage<T>(key: string): T {
    return JSON.parse(window.sessionStorage.getItem(key) ?? null);
  }
  //#endregion
}
