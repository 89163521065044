import { Injectable, Inject, Renderer2, RendererFactory2 } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Subject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class LoadingService {
  private renderer: Renderer2;
  private loadingSource = new Subject<boolean>();
  loadingSource$ = this.loadingSource.asObservable();

  constructor(rendererFactory: RendererFactory2, @Inject(DOCUMENT) private document: Document) {
    this.renderer = rendererFactory.createRenderer(null, null);
  }

  show() {
    this.renderer.addClass(document.body, 'loading-scroll-stop');
    this.loadingSource.next(true);
    return this;
  }

  hide() {
    this.renderer.removeClass(document.body, 'loading-scroll-stop');
    this.loadingSource.next(false);
    return this;
  }
}
