<div id="footer">
  <div class="footer-company">
    <p>Company : Weraser</p>
    <p>CEO : HyunJong Kim</p>
    <p>Company Registration Number : 679-87-01392</p>
    <p>Terms of Use Privacy Policy</p>
  </div>
  <div class="footer-address">
    <address>Address : (31169) 5th floor, 48, Buldang 14-ro, Seobuk-gu, Cheonan-si, Chungcheongnam-do</address>
    <p>Personal information manager : JaeWon Hwang (support@weraser.com)</p>
  </div>
  <ul>
    <li>
      <a>Terms of Use</a>
    </li>
    <li>
      <a>Privacy Policy</a>
    </li>
    <li>
      <a>Check Business Information</a>
    </li>
    <li>
      <a>Service Use Inquiry</a>
    </li>
  </ul>
  <p>© 2023 Weraser Corporation All rights reserved</p>
</div>
