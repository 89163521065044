import { Inject, Injectable, OnInit } from '@angular/core';
import { Router, Event as RouterEvent, NavigationEnd } from '@angular/router';
import { DOCUMENT } from '@angular/common';
import { Subject } from 'rxjs';
import { shareReplay } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class AppService {
  private $currentUrl: Subject<string> = new Subject<string>();
  currentUrl$ = this.$currentUrl.asObservable().pipe(shareReplay(1));

  wheelEvent: keyof WindowEventMap = 'wheel';

  constructor(private router: Router, @Inject(DOCUMENT) private document: Document) {
    // router subscribe
    this.router.events.subscribe((event: RouterEvent) => {
      // set current url
      if (event instanceof NavigationEnd) {
        this.$currentUrl.next(event.url);
      }
    });
  }

  disableScroll() {
    const scrollTarget: HTMLElement = this.document.querySelector('#content-corechat');
    const wheelOption: AddEventListenerOptions = { passive: false };
    scrollTarget.addEventListener(this.wheelEvent, this.preventDefault, wheelOption);
    scrollTarget.addEventListener('keydown', this.preventDefaultForScrollKeys, false);
  }

  enableScroll() {
    const scrollTarget: HTMLElement = this.document.querySelector('#content-corechat');
    const wheelOption: AddEventListenerOptions = { passive: false };
    scrollTarget.removeEventListener(this.wheelEvent, this.preventDefault, wheelOption);
    scrollTarget.removeEventListener('keydown', this.preventDefaultForScrollKeys, false);
  }

  private preventDefault = (e: Event) => {
    e.preventDefault();
  };

  private preventDefaultForScrollKeys = (e: KeyboardEvent) => {
    if (e.key === 'ArrowLeft' || e.key === 'ArrowRight') {
      e.preventDefault();
      return false;
    }
    return true;
  };
}
