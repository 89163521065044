<ng-container *ngIf="showContentYn">
  <ng-container *ngIf="withoutLayoutYn; else contentTpl">
    <router-outlet></router-outlet>
  </ng-container>
  <ng-template #contentTpl>
    <div id="content-corechat">
      <div id="content">
        <app-layout-header></app-layout-header>
        <router-outlet></router-outlet>
      </div>
      <!-- 2023.04.13 코어챗 코딩 시작 -->
      <app-core-chat></app-core-chat>
    </div>
  </ng-template>
</ng-container>
<!-- 2023.04.12 글로벌 로딩 html 시작 -->
<ng-container *ngIf="showLoadingYn">
  <div class="lodding">
    <span class="loader"></span>
  </div>
</ng-container>
