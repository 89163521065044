<div class="corechat" [@animation1]="openChatYn ? 'opened' : 'closed'">
  <div class="corechat-wrap">
    <div class="chat-character">
      <p>
        <img src="../assets/images/corechat.png" alt="corechat character" />
        <span>C</span>ore<span>C</span>hat
      </p>
      <button type="button" (click)="onCloseChat()">
        <img src="../assets/images/close.gif" alt="close btn" />
      </button>
    </div>
    <div class="chatting" #chatLayer>
      <div *ngFor="let message of messageList">
        <div class="corechat-character2">
          <div [ngClass]="message.role == 'user' ? 'chatting-question' : 'chatting-bot'">
            <p class="bot-character"><img src="../assets/images/corechat.png" alt="corechat character" /></p>
            <div [innerHTML]="message.message"></div>
            <p class="user-character"><img src="../assets/images/user.png" alt="corechat character" /></p>
          </div>
        </div>
      </div>
    </div>
    <div class="chat-text">
      <div class="chat-menu-bl-wrap" [@animation2]="showMenuYn ? 'show' : 'hide'">
        <div class="chat-menu">
          <ul>
            <li>
              <button type="button"><img src="../assets/images/corechat-icon.png" alt="menu-icon" /></button>
              <p>To Do</p>
            </li>
            <li>
              <button type="button"><img src="../assets/images/corechat-icon2.png" alt="menu-icon" /></button>
              <p>Schedule</p>
            </li>
            <li>
              <button type="button"><img src="../assets/images/corechat-icon3.png" alt="menu-icon" /></button>
              <p>Shipment</p>
            </li>
            <li>
              <button type="button"><img src="../assets/images/corechat-icon4.png" alt="menu-icon" /></button>
              <p>Vessel</p>
            </li>
            <li>
              <button type="button"><img src="../assets/images/corechat-icon5.png" alt="menu-icon" /></button>
              <p>Customer</p>
            </li>
            <li>
              <button type="button" (click)="onOpenEmailModal()">
                <img src="../assets/images/corechat-icon6.png" alt="menu-icon" />
              </button>
              <p>e-mail</p>
            </li>
          </ul>
        </div>
        <div class="chat-bl-text">
          <!-- <select name="bl-select" #categorySelect>
            <option value="MBL">M-B/L No.</option>
            <option value="HBL">H-B/L No.</option>
            <option value="CONTAINER">Container No.</option>
            <option value="VESSEL">Vessel Name</option>
          </select> -->
          <nz-select
            [(ngModel)]="categorySelected"
            [nzDropdownMatchSelectWidth]="false"
            (nzOpenChange)="onNzOpenChange($event)"
          >
            <nz-option nzValue="MBL" nzLabel="M-B/L No."></nz-option>
            <nz-option nzValue="HBL" nzLabel="H-B/L No."></nz-option>
            <nz-option nzValue="CONTAINER" nzLabel="Container No."></nz-option>
            <nz-option nzValue="VESSEL" nzLabel="Vessel Name"></nz-option>
          </nz-select>
          <input type="text" placeholder="Input category text here" #categoryMessage />
          <label for="chat-file"></label>
          <input type="file" id="chat-file" />
        </div>
      </div>
      <div class="chat-mes-text">
        <button type="button" (click)="onVisible()">
          <img
            [@animation3]="showMenuYn ? 'rotate45' : 'rotate0'"
            src="../assets/images/plus.png"
            alt="plus icon"
          />
        </button>
        <input
          type="text"
          placeholder="Input your question here"
          #userMessage
          (keyup.enter)="onInputUserMessage(userMessage.value)"
        />
        <button type="button" (click)="onInputUserMessage(userMessage.value)">
          <img src="../assets/images/send.png" alt="send icon" />
        </button>
      </div>
    </div>
  </div>
</div>

<nz-modal
  [nzVisible]="emailModalOpenYn"
  nzTitle="Email writing assistant"
  [nzClosable]="false"
  [nzFooter]="null"
  nzWidth="800px"
>
  <ng-container *nzModalContent>
    <!-- 탭추가 -->
    <!-- 닫기버튼 추가 -->
    <div>
      <a title="닫기" class="icon common-close ng-tns-c64-10 booking-master-close" (click)="onCloseEmailModal()"
        >x</a
      >
    </div>
    <app-email-helper></app-email-helper>
  </ng-container>
</nz-modal>
