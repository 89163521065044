import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
// services
import { LoadingService } from 'src/app/core/services/loading/loading.service';

@Injectable({ providedIn: 'root' })
export class CoreChatService {
  private $srCompleteToggle = new BehaviorSubject<boolean>(null);
  srCompleteToggle$ = this.$srCompleteToggle.asObservable();

  private $openChatYn = new BehaviorSubject<boolean>(true);
  openChatYn$ = this.$openChatYn.asObservable();

  constructor(private loadingService: LoadingService) {}

  toggleSrComplete() {
    this.$srCompleteToggle.next(!this.$srCompleteToggle.value);
  }

  toggleOpenChatYn() {
    this.$openChatYn.next(!this.$openChatYn.value);
  }
  closeChat() {
    this.$openChatYn.next(false);
  }
}
