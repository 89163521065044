import { Component } from '@angular/core';
import { filter } from 'rxjs/operators';
// services
import { AppService } from './app.service';
import { LoadingService } from 'src/app/core/services/loading/loading.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
})
export class AppComponent {
  // avoid show header and footer when page is loading
  showContentYn: boolean = false;
  // without layout setting
  withoutLayoutYn: boolean;
  withoutLayoutPages: string[] = ['/login', '/not-found'];
  // loading
  showLoadingYn: boolean = false;

  constructor(private loadingService: LoadingService, private appService: AppService) {
    // router subscribe
    this.appService.currentUrl$.pipe(filter((url) => url !== undefined)).subscribe((url: string) => {
      if (this.withoutLayoutPages.indexOf(url) > -1) {
        this.withoutLayoutYn = true;
      } else {
        this.withoutLayoutYn = false;
      }
      this.showContentYn = true;
    });

    // loading subscribe
    this.loadingService.loadingSource$.subscribe((showLoadingYn: boolean) => {
      this.showLoadingYn = showLoadingYn;
    });
  }
}
